<template>
    <div class="equipment-waterquality-base">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <router-view class="equipment-waterquality-view"></router-view>
    </div>
</template>
<script>
export default {
    name: "equipment_waterquality",
    data() {
        return {
            itemName: '地图服务管理',
            breadlist: [
                {
                    path: '',
                    name: '数据管理与接入'
                }
            ],
        }
    }
}
</script>
<style scoped>
.equipment-waterquality-base{
    width: 100%;
    height: 100%;
}
.equipment-waterquality-view{
    width: 100%;
    height: calc(100% - 45px);
}
</style>
